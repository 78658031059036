import DagreGraph from "dagre-d3-react"
import React, { useState } from "react"
import { navigate } from "gatsby"
import { toProperCourseId, cleanCourseId } from "../../utils/course-namer"
import { CourseQuickView } from "./course-quick-view"
import COURSE_LIST from "../../data/autogenerated_course_info.json"

const getCourseData = (courseId) => {
  if (!courseId) return {}
  return COURSE_LIST.courses.filter(({ id }) => id === courseId)[0]
}

/*
Usage <CourseGraph reqs={ 
	[
		["CMP401", "CS445"],
		["CS445", "CS447"],
		["CS447", "CS449", { type: 'coreq' }],
		["CS449", "CS1501"]
	]
 }
*/

const addNode = (id, nodeHash, nodes, currentCourse) => {
  if (!nodeHash[id]) {
    nodeHash[id] = true
    nodes.push({
      label: cleanCourseId(id),
      id: id,
      class: "course cursor-pointer " + (currentCourse === id ? "active" : ""),
      config: {
        width: 50,
        rx: 5,
        ry: 5,
      },
    })
  }
}

export default ({ reqs, showPreview = false }) => {
  const nodes = []
  const nodeHash = {}
  const links = []
  const [currentCourse, setCurrentCourse] = useState(null)

  reqs.forEach((req) => {
    const link = {
      source: toProperCourseId(req[0]),
      target: toProperCourseId(req[1]),
      class: req[2] ? req[2].type : "prereq",
    }
    addNode(link.source, nodeHash, nodes, currentCourse)
    addNode(link.target, nodeHash, nodes, currentCourse)
    links.push(link)
  })

  const onNodeClick = showPreview
    ? ({ original: { id } }) => setCurrentCourse(id)
    : ({ original: { id } }) => navigate(`/courses/${id}`)

  return (
    <div className="lg:flex">
      <div className="w-full overflow-x-auto">
        <DagreGraph
          nodes={nodes}
          links={links}
          fitBoundaries="false"
          height="500"
          width="480"
          shape="rect"
          className="course-graph mx-auto"
          onNodeClick={onNodeClick}
        />
      </div>
      <div className="mb-5 border p-6 flex flex-col rounded br-8 lg:absolute lg:rounded-r-none lg:right-0 lg:w-1/4 shadow-md lg:border-r-0">
        <CourseQuickView {...getCourseData(currentCourse)}>
          <h3>Click a course on the graph to see details</h3>
          <p>
            The diagram <span className="md:hidden">above</span> shows the
            relationships between the core classes!
          </p>
        </CourseQuickView>
      </div>
    </div>
  )
}
