import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/templates/mdx-guide-template.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const CourseGraph = makeShortcode("CourseGraph");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Let's look at the core CS classes required for the major. It
might look intimadating at first, but it just shows the requirements
of classes. For example, to take CS 1501, you must take CS 441 and CS 445.`}</p>
    <p>{`The dashed lines mean those classes are "coreqs". For example, you can take CS 449 if you are taking CS 447 in the semester. But, you cannot take
CS 449 before CS 447.`}</p>
    <CourseGraph reqs={[["CMP401", "CS445"], ["CS441", "CS1502"], ["CS441", "CS1501"], ["CS445", "CS447", {
      type: "coreq"
    }], ["CS445", "CS1502"], ["CS445", "CS449"], ["CS447", "CS449", {
      type: "coreq"
    }], ["CS445", "CS1501"], ["CS447", "CS1550"], ["CS449", "CS1550"]]} showPreview="true" mdxType="CourseGraph" />
    <h2>{`Overview`}</h2>
    <p>{`The core courses can be broken up into different tracks.`}</p>
    <p><strong parentName="p">{`Core Programming Intensive`}</strong></p>
    <p>{`These classes teach the most fundamental CS concepts. `}<strong parentName="p">{`Bolded classes`}</strong>{` are considered very demanding. `}</p>
    <ul>
      <li parentName="ul">{`CMP 401`}</li>
      <li parentName="ul">{`CS 445`}</li>
      <li parentName="ul"><strong parentName="li">{`CS 1501`}</strong></li>
    </ul>
    <p><strong parentName="p">{`Mathy`}</strong></p>
    <p>{`These are more theory based classes that involve proofs and math.`}</p>
    <ul>
      <li parentName="ul">{`CS 441`}</li>
      <li parentName="ul">{`CS 1502`}</li>
    </ul>
    <p><strong parentName="p">{`Systems`}</strong></p>
    <p>{`Do you actually know how a computer works? These classes will teach you!`}</p>
    <ul>
      <li parentName="ul">{`CS 447`}</li>
      <li parentName="ul"><strong parentName="li">{`CS 449`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`CS 1550`}</strong></li>
    </ul>
    <h2>{`Related Links`}</h2>
    <p>{`The full requirements: `}<a parentName="p" {...{
        "href": "https://www.sci.pitt.edu/academics/undergraduate-majors/computer-science"
      }}>{`https://www.sci.pitt.edu/academics/undergraduate-majors/computer-science`}</a></p>
    <p>{`More information here: `}<a parentName="p" {...{
        "href": "http://sci.pitt.edu/academics/ugrad/cs/"
      }}>{`http://sci.pitt.edu/academics/ugrad/cs/`}</a></p>
    <p>{`CS Capstone Guide: `}<a parentName="p" {...{
        "href": "https://pittcs.wiki/academics/registration/capstone/"
      }}>{`https://pittcs.wiki/academics/registration/capstone/`}</a></p>
    <h2>{`Potential Schedules`}</h2>
    <p>{`We recommend taking CS445 and CS1501 as soon as possible. These courses form the foundation of your CS education and will unlock a lot of upper electives. They also are an absolute necessity for technical interviews. `}</p>
    <p>{`Making a Good CS Plan`}</p>
    <ol>
      <li parentName="ol">{`Take 401, 445, and 1501 early on`}</li>
      <li parentName="ol">{`Decide which semester you will take 1501.`}</li>
      <li parentName="ol">{`Throw away the plan (in college, things change!)`}</li>
    </ol>
    <p>{`Below are some potential schedules. Your schedule will look different depending on your other classes!`}</p>
    <p>{`Whenever you schedule a class make sure to `}<strong parentName="p">{`play close attention to the professor`}</strong>{`. It's better to optimize your schedule
to have better professors good. Check the respective pages for each course on the wiki and use Rate My Professor.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` These `}<strong parentName="p">{`are just example schedules`}</strong>{`. Everyone's situation is different and yours will likely look different.`}</p>
    <h3>{`A - Common`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 1`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 2`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 3`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 4`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 5`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`447`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1501`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`449`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1550`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`441`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`445`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1502`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CS Elective`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CS Elective`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`B - Common`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 1`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 2`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 3`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 4`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 5`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`441`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`447`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`449`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1550`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`445`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1501`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1502`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h3>{`C - Common`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 1`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 2`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 3`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 4`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`447`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`449`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1502`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`441`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`445`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1501`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1550`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`D - Common`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 1`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 2`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 3`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 4`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 5`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`007/ 010`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`401`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`445`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1501`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`449`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`441`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`447`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1502`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CS Elective`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`E - Common`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 1`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 2`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 3`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 4`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 5`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`441`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`447`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1501`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1550`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`445`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`449`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1502`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CS Elective`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`F - Slowish Start`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 1`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 2`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 3`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 4`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 5`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`007/ 010`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`401`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`445`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1501`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`449`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`441`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`447`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1502`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`G - Slowish Start (Faster)`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 1`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 2`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 3`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 4`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 5`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`007/ 010`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`401`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`445`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1501`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`449`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`441`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1502`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CS Elective`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`447`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h3>{`H - Slowish Start (Fastest)`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 1`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 2`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 3`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 4`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 5`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`007/ 010`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`401`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`445`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1501`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1550`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`441`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1502`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CS Elective`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`447`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`449`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h3>{`I - AP Credit required`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 1`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 2`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 3`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 4`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`445`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`447`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1501`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1550`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`441`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`449`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1502`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CS Elective`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`J - Tryhard`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 1`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 2`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 3`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Semester 4`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`447`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1501`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1550`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`441`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`445`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`449`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CS Elective(s)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1502`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h2>{`What should I take with these CS classes?`}</h2>
    <p>{`It depends on the rigor of the CS course, and the professor. Notably, if we are talking about CS 1501, we would not want a heavy course load. We recommend not taking Calculus II with CS1501/CS1550 as they are very demanding. Instead, take Calc with a lower level required course like 401 or 449 or take it with your upper electives!`}</p>
    <p>{`We would likely take some light gen-eds with CS 1501 or CS 1550. As noted, they are demanding and you wouldn't want other tough classes from interfering with them.`}</p>
    <h2>{`CS Electives`}</h2>
    <p>{`You discover your interests in CS as you take some electives. You’re not limited to take classes in a certain domain, but try to take classes that you'll enjoy. Electives are meant to cover topics you want to explore, not classes you suffer in. For example, if you didn’t enjoy CS 447, 449, or 1550 you probably don’t want to take Computer Architecture or other low level courses. On the other hand, if you enjoy certain parts of CS 1501, such as the introduction to cryptography section, CS 1653 would probably be a good elective to take.`}</p>
    <p>{`Check out the `}<a parentName="p" {...{
        "href": "/courses"
      }}>{`course explorer`}</a>{` to look at the different electives that are offered. Pick the ones that pique your interest.`}</p>
    <h2>{`CSC's highly subjective CS Electives Difficulty Ranking`}</h2>
    <p>{`These rankings are incredibly subjective and difficulty varies more by professor rather than course. Note that courses in the unknown tier have not been offered since at least Fall 2022. The professors listed are from Spring, Summer and Fall 2023 and are subject to change.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`S Tier: Rough`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Professors`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Offered`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CS 1510 - Algorithm Design`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.ratemyprofessors.com/ShowRatings.jsp?tid=105280"
            }}>{`Kirk Pruhs`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fall`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CS 1511 - Theory of Computation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.ratemyprofessors.com/ShowRatings.jsp?tid=105280"
            }}>{`Kirk Pruhs`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Spring`}</td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`A Tier: A Tough Challenge`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Professors`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Offered`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CS 1541 - Intro to Computer Architecture`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.ratemyprofessors.com/ShowRatings.jsp?tid=2686772"
            }}>{`Xulong Tang`}</a>{`, `}<a parentName="td" {...{
              "href": "https://www.ratemyprofessors.com/ShowRatings.jsp?tid=2167851"
            }}>{`Wonsun Ahn`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fall, Spring`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CS 1622 - Intro to Compiler Design`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.ratemyprofessors.com/ShowRatings.jsp?tid=493257"
            }}>{`Jonathan Misurda`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Summer`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CS 1652 - Data Communication and Computer Networks`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.ratemyprofessors.com/ShowRatings.jsp?tid=2891315"
            }}>{`Longfei Shangguan`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Spring`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CS 1675 - Intro to Machine Learning`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.ratemyprofessors.com/ShowRatings.jsp?tid=2576347"
            }}>{`Joseph Yurko`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fall, Spring`}</td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`B Tier: Quite a Challenge`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Professors`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Offered`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CS 1566 - Intro to Computer Graphics`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.ratemyprofessors.com/ShowRatings.jsp?tid=1863081"
            }}>{`Dr. Tan (Thumrongsak Kosiyatrakul)`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fall`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CS 1571 - Intro to Artificial Intelligence`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.ratemyprofessors.com/ShowRatings.jsp?tid=138894"
            }}>{`Diane Litman`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Spring`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CS 1653 - Applied Crypto and Network Security`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.ratemyprofessors.com/ShowRatings.jsp?tid=2055608"
            }}>{`William Garrison`}</a>{`, `}<a parentName="td" {...{
              "href": "https://www.ratemyprofessors.com/ShowRatings.jsp?tid=2313893"
            }}>{`Shreif Khattab`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Summer, Fall`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CS 1657 - Privacy in Electronic Society`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.ratemyprofessors.com/ShowRatings.jsp?tid=2055608"
            }}>{`William Garrison`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Spring`}</td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`C Tier: A Challenge`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Professors`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Offered`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CS 1555 - Database Management Systems`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.ratemyprofessors.com/ShowRatings.jsp?tid=126798"
            }}>{`Panos Chrysanthis`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fall, Spring`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CS 1567 - Programming System Design On A Mobile Robot Platform`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.ratemyprofessors.com/ShowRatings.jsp?tid=1863081"
            }}>{`Dr. Tan (Thumrongsak Kosiyatrakul)`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Summer`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CS 1637 - Intro to Human Computer Interaction`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.ratemyprofessors.com/ShowRatings.jsp?tid=2483140"
            }}>{`Erin Walker`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Spring`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CS 1666 - Principles of Computer Game Design and Implementation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.ratemyprofessors.com/ShowRatings.jsp?tid=1894664"
            }}>{`Nick Farnan`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fall`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CS 1671 - Human Language Technologies`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fall`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CS 1699 - Special Topics (these vary in difficulty, but because they're experiemental they're graded leniently)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`D Tier: Not Bad`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Professors`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Offered`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CS 1520 - Programming Language for Web Applications`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.ratemyprofessors.com/ShowRatings.jsp?tid=1894664"
            }}>{`Nick Farnan`}</a>{`, `}<a parentName="td" {...{
              "href": "https://www.ratemyprofessors.com/ShowRatings.jsp?tid=2717007"
            }}>{`Paulo Ferreira`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fall, Spring, Summer`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CS 1530 - Software Engineering`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.ratemyprofessors.com/ShowRatings.jsp?tid=2450152"
            }}>{`Sohel Sarwar`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fall, Spring`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CS 1621 - Structure Programming Languages`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.ratemyprofessors.com/ShowRatings.jsp?tid=943715"
            }}>{`Youtao Zhang`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Spring`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CS 1632 - Software Quality Assurance`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.ratemyprofessors.com/ShowRatings.jsp?tid=2167851"
            }}>{`Wonsun Ahn`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fall, Spring, Summer`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CS 1656 - Intro to Data Science`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.ratemyprofessors.com/ShowRatings.jsp?tid=138892"
            }}>{`Alexandros Labrinidis`}</a>{`, `}<a parentName="td" {...{
              "href": "https://www.ratemyprofessors.com/professor/2728179"
            }}>{`Xiaowei Jia`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fall, Spring`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CS 1660 - Intro to Cloud Computing`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Daniel Mahoney`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fall`}</td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Unknown Tier: Haven't Been Offered In A While`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Previously`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CS 1651 - Advanced Systems Software`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A Tier`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CS 1645 - Intro to High Performance Computing Systems`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`B Tier`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CS 1674 - Intro to Computer Vision`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`C Tier`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CS 1678 - Intro to Deep Learning`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`C Tier`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`FAQ`}</h2>
    <h3>{`CS 0007 or CS 401?`}</h3>
    <p>{`Should you start with `}<strong parentName="p">{`Intro to Programming`}</strong>{` or `}<strong parentName="p">{`Intermediate Programming?`}</strong>{` Cases by expereince levels: `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`You have never written a single line of code (like me as a freshman):`}</strong>{` Take 0007. `}
        <ul parentName="li">
          <li parentName="ul"><em parentName="li">{`Caveat:`}</em>{` If you have enough time before the semester starts, watch online tutorials to learn Java and start with 401! If you can learn -- variables, assignments, if-statements, logical operators, functions, Strings, lists, input/output, and loops -- before the semester, you can probably take 401.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`You took a high school-level programming course:`}</strong>{` Take 401, `}<em parentName="li">{`unless`}</em>{` the programming course was in a language like HTML/CSS.`}
        <ul parentName="li">
          <li parentName="ul"><em parentName="li">{`Caveat:`}</em>{` If the course wasn't in Java, you'll have to learn the syntax. Learning syntax doesn't take long, just look at code examples from the textbook.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`You took a college-level for-credit programming course:`}</strong>{` Take 445. `}
        <ul parentName="li">
          <li parentName="ul"><em parentName="li">{`Caveat:`}</em>{` You'll definitely want to review OOP and algorithm analysis, which are the core concepts of DS&A 1. If the course wasn't in Java, 445 will be a little more difficult. Get up to speed by learning Java syntax before the semester via textbooks/video tutorials and... write a lot of code. `}</li>
        </ul>
      </li>
    </ul>
    <p><em parentName="p">{`Note:`}</em>{` Don't be fooled/intimidated by the sudden jump in the course number `}<em parentName="p">{`CS0007`}</em>{` to `}<em parentName="p">{`CS0401`}</em>{`. They are sequential courses, and depending on your professors, they may even cover a lot of the same material. Use the pre-reqs to gauge course difficulty, not the course number.`}</p>
    <h3>{`Should I Graduate Early?`}</h3>
    <p>{`If you have enough credits, you can graduate early! It is a great way to save some tuition money. But, there are also some more classes that can help you as a CS major.`}</p>
    <p>{`It really depends on what your interests are. For example, if you are interested in Data Science, we would recommend taking some Statistics classes. In particular, STAT 1261, and STAT 1201 would be excellent options.`}</p>
    <p>{`If you are interested in Cryptography, we would recommend taking some Math classes. In particular, Introduction to Mathematical Cryptography, Elementary Number Theory, Combinatorial Mathematics, would be great options. Note that all these classes require MATH 413.`}</p>
    <p>{`If you're interested in writing/improving your communication and expression skills, the Creative Writing minor is a great choice!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      